import React from 'react';
import App from './App';
import StepsProvider from './Context';

export default function index() {
  return (
    <>
      <div style={{ background: '#F3F3F3', height: '100vh', position: 'fixed' }}></div>
      <StepsProvider>
        <App />
      </StepsProvider>
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
